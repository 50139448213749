<script setup>
import {computed, ref, watch} from "vue";
import SideBarMenuItem from "@/Components/Menus/SideBarMenuItem.vue";
import {bag, cog, exit, helpCircle, listOutline, wallet, barChart, star} from "ionicons/icons";
import {IonIcon} from '@ionic/vue';
import {usePage} from "@inertiajs/vue3";
import Profile from "@/Components/Icons/Profile.vue";
import route from "ziggy-js";
import {HelpDeskUrl, tgSupportUrl} from "@/constants.js";
import Airplane from "@/Components/Icons/Airplane.vue";

const props = defineProps({
    modelValue: Boolean,
})
const emit = defineEmits(['update:modelValue']);
const showSideBar = ref(false)
watch(() => props.modelValue, (val) => {
    if (val) {
        setTimeout(() => {
            showSideBar.value = true
        }, 1)
    } else {
        showSideBar.value = false
    }
})
const close = () => {
    showSideBar.value = false
    setTimeout(() => {
        emit('update:modelValue', false)
    }, 200)
}
const page = usePage()
const isAuthenticated = computed(() => page.props.auth.user ?? false)
</script>

<template>
    <div v-if="modelValue" class="fixed left-0 top-0 h-screen flex w-screen z-20 justify-between">
        <div class="flex-auto bg-transparent" @click="close"></div>
        <aside :class="{'translate-x-96' : !showSideBar}"
               class="h-full w-3/5 transition-transform ease-out duration-200 max-w-[240px] sm:max-w-[420px] sm:max-w-[512px]">
            <div class="h-full px-2 pt-3 py-4 overflow-y-auto bg-deep-blue">
                <div class="flex justify-end">
                    <div class="mr-3 align-top">
                        <span class="text-gray-300 font-condensed mr-1 px-1 align-top text-sm">{{
                                page.props.auth.user?.name ?? ''
                            }}</span>
                        <span class="bg-green-600 rounded-full pr-1 text-white text-xs align-top">
                            Online
                        </span>
                    </div>
                    <div v-if="isAuthenticated"
                         class="w-16 h-16 bg-gray-200 rounded-full border border-gray-300 shrink-0">
                        <img class="w-full h-full rounded-full" :src="page.props.auth.user.profile_photo_url"
                             alt="avatar"/>
                    </div>
                    <Profile v-else class="border border-gray-300"/>
                </div>
                <ul class="font-medium pt-2">
                    <SideBarMenuItem :active="route().current('orders.index')" :url="route('orders.index')"
                                     :title="$t('MY PURCHASES')">
                        <ion-icon :icon="bag" class="text-2xl"></ion-icon>
                    </SideBarMenuItem>
                    <SideBarMenuItem :url="route('user.offers')" :title="$t('MY OFFERS')"
                                     :active="route().current('user.offers')">
                        <ion-icon :icon="listOutline" class="text-2xl"></ion-icon>
                    </SideBarMenuItem>
                    <SideBarMenuItem :active="route().current('sales.index')" :url="route('sales.index')"
                                     :title="$t('MY SALES')">
                        <ion-icon :icon="barChart" class="text-2xl"></ion-icon>
                    </SideBarMenuItem>
                    <SideBarMenuItem :url="route('transactions.index')" :title="$t('WALLET')">
                        <ion-icon :icon="wallet" class="text-2xl"></ion-icon>
                    </SideBarMenuItem>
                    <SideBarMenuItem :url="route('review.index')" :title="$t('Rating and reviews')">
                        <ion-icon :icon="star" class="text-2xl"></ion-icon>
                    </SideBarMenuItem>
                    <li class="border-b border-dashed border-gray-200 last:border-none text-gray-300 text-sm
  hover:text-blue-400 hover:border-blue-400 uppercase">
                        <a
                            :href="HelpDeskUrl"
                            class="flex items-center justify-end py-3 flex-row-reverse"
                            target="_blank"
                        >
                            <span class="mx-2">{{ $t('HELP CENTER') }}</span>
                            <ion-icon :icon="helpCircle" class="text-2xl"></ion-icon>
                        </a>
                    </li>
                    <li class="border-b border-dashed border-gray-200 last:border-none text-gray-300 text-sm
  hover:text-blue-400 hover:border-blue-400 uppercase group">
                        <a
                            :href="tgSupportUrl"
                            class="flex items-center justify-end py-3 flex-row-reverse"
                            target="_blank"
                        >
                            <span class="mx-2">{{$t('Support in Telegram')}}</span>
                            <span class="block w-5 h-5 flex">
                                <Airplane class="m-auto group-hover:fill-blue-400 transition-colors"/>
                            </span>
                        </a>
                    </li>
                    <SideBarMenuItem :title="$t('SETTINGS')" :url="route('profile.show')"
                                     :active="route().current('profile.show')">
                        <ion-icon :icon="cog" class="text-2xl"></ion-icon>
                    </SideBarMenuItem>
                    <SideBarMenuItem :url="route('logout')" method="post" :title="$t('LOG OUT')">
                        <ion-icon :icon="exit" class="text-2xl"></ion-icon>
                    </SideBarMenuItem>
                </ul>
            </div>
        </aside>

    </div>
    <div v-if="modelValue" class="h-full bg-black opacity-50 z-10 fixed left-0 top-0 h-screen w-screen"></div>
</template>
